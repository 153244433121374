import { Stars } from "@react-three/drei";

import { Canvas } from "@react-three/fiber";

import React, { useEffect } from "react";

import { FiArrowRight } from "react-icons/fi";

import {
  useMotionTemplate,
  useMotionValue,
  motion,
  animate,
} from "framer-motion";
import styled from "styled-components";

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const LandingBG = () => {
      const color = useMotionValue(COLORS_TOP[0]);

      useEffect(() => {
        animate(color, COLORS_TOP, {
          ease: "easeInOut",
          duration: 10,
          repeat: Infinity,
          repeatType: "mirror",
        });
      }, []);

      const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
      const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;
    
    return (
      <ShinyBG>
        <div className="overlay"></div>
        <motion.section
          style={{ height: "100vh", backgroundImage }}
          className="relative grid min-h-screen place-content-center overflow-hidden bg-gray-950 px-4 py-24 text-gray-200"
        >
          

          <div className="absolute inset-0 z-0 stars">
            <Canvas>
              <Stars radius={50} count={2500} factor={4} fade speed={2} />
            </Canvas>
          </div>
        </motion.section>
      </ShinyBG>
    );
};

const ShinyBG = styled.div`
width:100%;
height:100%;
position:relative;
.stars{
    height:100%;
    width:100%;
}
.overlay{
    position:absolute;
    height:100vh;
    width:100%;
    z-index:1;
    background:rgba(0,0,0,.6);
    display:block;
}
`

export default LandingBG;
