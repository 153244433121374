import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LandingBG from "../components/LandingBG";
import { GrFormNextLink } from "react-icons/gr";
import { CgKey } from "react-icons/cg";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoQrCodeSharp } from "react-icons/io5";
import LoaderComponent from "../components/LoaderComponent";

const Successful = () => {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccess(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const allTexts: string[] = [
    "Configuring             ",
    "Esthablishing Connection",
    "Connecting.             ",
    "Connecting..            ",
    "Connecting...           ",
  ];
  const [text, setText] = useState(allTexts[0]);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (index < allTexts.length - 1) {
      const timer = setTimeout(() => {
        setIndex(index + 1);
        setText(allTexts[index + 1]);
      }, 2000); // 2 seconds

      // Clean up the timer when the component unmounts or index changes
      return () => clearTimeout(timer);
    }
  }, [index, allTexts]);

  return (
    <SuccessPage>
      <LoaderComponent connectModal={success} text={text} />
      {success && (
        <>
          <div className="private-content">
            <div className="private-top">
              <p className="priv-header">CONNECTION SUCCESSFUL</p>
            </div>
            <div className="the-key">
              <IoCheckmarkCircleSharp className="key " />
            </div>
            <div className="bar-code">
              <IoQrCodeSharp className="code" />
            </div>
            <p className="contact-msg">Chat Admin up for authentication Code</p>
            <button type="submit" onClick={() => navigate("/")}>
              PROCEED <GrFormNextLink />
            </button>
          </div>
        </>
      )}

      <div className="landing-bg">
        <LandingBG />
      </div>
    </SuccessPage>
  );
};

const SuccessPage = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .landing-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    /* display: none; */
  }
  .private-content {
    width: 50%;
    position: absolute;
    z-index: 10;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: rgba(0, 0, 0, 0.8);
    .priv-header {
      font-size: 1.2rem;
      font-weight: 500;
      color: white;
      letter-spacing: 1px;
    }
    .priv-word {
      font-size: 0.7rem;
      color: white;
    }
    .the-key {
      width: 5rem;
      /* background: #; */
      display: flex;
      align-items: center;
      justify-content: center;
      .key {
        color: #609612;
        font-size: 5rem;
        border-radius: 50%;
        border: 10px solid #d6ff9d7f;
        /* padding:1rem; */
      }
    }
    .bar-code {
      display: flex;
      align-items: center;
      justify-content: center;
      .code {
        color: white;
        font-size: 8rem;
        margin: 1rem 0; /* border: 10px solid #d6ff9d7f; */
      }
    }
    .contact-msg {
      color: white;
      font-size: 1rem;
    }
    button {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      border: 1px solid #609612;
      padding: 0.5rem;
      border-radius: 0.4rem;
      font-size: 0.8rem;
      color: white;
      background: #609612;
      transition: 400ms all ease-in;
      &:hover {
        background: #d6ff9d7f;
        transition: 400ms all ease-in;
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .landing-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      /* display: none; */
    }
    .private-content {
      width: 90%;
      position: absolute;
      z-index: 10;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -40%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      background: rgba(0, 0, 0, 0.8);
      .priv-header {
        font-size: 1.2rem;
        font-weight: 500;
        color: white;
        letter-spacing: 1px;
      }
      .priv-word {
        font-size: 0.7rem;
        color: white;
      }
      .the-key {
        width: 5rem;
        /* background: #; */
        display: flex;
        align-items: center;
        justify-content: center;
        .key {
          color: #609612;
          font-size: 5rem;
          border-radius: 50%;
          border: 10px solid #d6ff9d7f;
          /* padding:1rem; */
        }
      }
      .bar-code {
        display: flex;
        align-items: center;
        justify-content: center;
        .code {
          color: white;
          font-size: 8rem;
          margin: 1rem 0; /* border: 10px solid #d6ff9d7f; */
        }
      }
      .contact-msg {
        color: white;
        font-size: 1rem;
      }
      button {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        border: 1px solid #609612;
        padding: 0.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        color: white;
        background: #609612;
        transition: 400ms all ease-in;
        &:hover {
          background: #d6ff9d7f;
          transition: 400ms all ease-in;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default Successful;
