import styled from "styled-components";
import { GrSave } from "react-icons/gr";
import { useState } from "react";
import TwelveWords from "./TwelveWords";
import TwentyFourWords from "./TwentyFourWords";

const SeedPhrase = () => {
  const [activeWords, setActiveWords] = useState("#12");

  return (
    <SPhrase>
      <div className="private-content">
        <GrSave size={25} className="key " />
        <div className="private-top">
          <p className="priv-header">Enter Seed Phrase</p>
          <p className="priv-word">Usually 12 or 24 Words.</p>
        </div>
      </div>
      <div className="word-count">
        <p
          onClick={() => setActiveWords("#12")}
          className={
            activeWords === "#12" ? "the-words-highlighted" : "twelve-words"
          }
        >
          12 Words
        </p>
        <p
          onClick={() => setActiveWords("#24")}
          className={
            activeWords === "#24" ? "the-words-highlighted" : "twentyfour-words"
          }
        >
          24 Words
        </p>
      </div>
      <div className="twelve-twentyfour">
        {activeWords === "#12" ? <TwelveWords /> : <TwentyFourWords />}
      </div>
    </SPhrase>
  );
};

const SPhrase = styled.div`
  width: 100%;
  .private-content {
    display: flex;
    align-items: start;
    justify-content: start;
    line-height: 0.9rem;
    gap: 0.4rem;
    .priv-header {
      font-size: 0.8rem;
      font-weight: 500;
      color: white;
    }
    .priv-word {
      font-size: 0.7rem;
      color: white;
    }
    .key {
      transform: rotate(90deg);
      margin-left: -0.2rem;
      color: white;
    }
  }
  .word-count {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 1rem;
    gap: 1rem;
    .twelve-words,
    .twentyfour-words {
      font-size: 0.75rem;
      font-weight: 500;
      color: white;
      cursor: pointer;
    }
    .the-words-highlighted {
      font-size: 0.75rem;
      font-weight: 500;
      border-bottom: 2px solid #1565d8;
      cursor: pointer;
      color: white;
    }
  }
  .twelve-twentyfour {
    margin-top: 1rem;
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    .private-content {
      display: flex;
      align-items: start;
      justify-content: start;
      line-height: 0.9rem;
      gap: 0.4rem;
      .priv-header {
        font-size: 0.8rem;
        font-weight: 500;
        color: white;
      }
      .priv-word {
        font-size: 0.7rem;
        color: white;
      }
      .key {
        transform: rotate(90deg);
        margin-left: -0.2rem;
        color: white;
      }
    }
    .word-count {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: .5rem;
      gap: 1rem;
      .twelve-words,
      .twentyfour-words {
        font-size: 0.6rem;
        font-weight: 500;
        color: white;
        cursor: pointer;
      }
      .the-words-highlighted {
        font-size: 0.6rem;
        font-weight: 500;
        border-bottom: 2px solid #1565d8;
        cursor: pointer;
        color: white;
      }
    }
    .twelve-twentyfour {
      margin-top: .5rem;
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default SeedPhrase;
