import React from "react";
import LandingBG from "./LandingBG";
import styled from "styled-components";
import AllTabs from "../pages/AllTabs";

const Landing = () => {
  return (
    <LandingPage>
      <div className="landing-components">
        <h1 className="landing-heading text-center w-[100%] text-primary font-semibold">
          CONNECT YOUR WEB3 WALLET
        </h1>
        <div className="components">
          <AllTabs />
        </div>
      </div>
      <div className="landing-bg">
        <LandingBG />
      </div>
    </LandingPage>
  );
};

const LandingPage = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .landing-heading {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
  }
  .landing-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    /* display: none; */
  }
  .landing-components {
    margin-top: 5vh;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
  @media screen and (max-width: 1200px) {
    position: relative;
    width: 100%;
    height: fit-content;
    .landing-heading {
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1rem;
    }
    .landing-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      /* display: none; */
    }
    .landing-components {
      margin-top: 10vh;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
    }
  }
  @media screen and (max-width: 900px) {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .landing-heading {
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .landing-heading {
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.8rem;
    }
    .landing-components {
      margin-top: 10vh;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
    }
  }
  @media screen and (max-width: 420px) {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .landing-heading {
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.2rem;
    }
    .landing-components {
      margin-top: 2vh;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
    }
  }
  @media screen and (max-width: 350px) {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .landing-heading {
      font-family: "Raleway", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 2rem;
    }
  }
`;

export default Landing;
