import { useState } from "react";
import styled from "styled-components";
import PrivateKey from "../components/PrivateKey";
import SeedPhrase from "../components/SeedPhrase";





const AllTabs = () => {
const [activeTab, setActiveTab] = useState(true)
  return (
    <NavTabs>
      <div className="navigation">
        <nav>
          <button
            onClick={() => setActiveTab(true)}
            className={
              activeTab ? "highlightedBTN" : "btn-class"
            }
          >
            PRIVATE KEY
          </button>
          <button
            onClick={() => setActiveTab(false)}
            className={
              !activeTab  ? "highlightedBTN" : "btn-class"
            }
          >
            SEED PHRASE
          </button>
        </nav>
      </div>
      {activeTab ? (
        <>
          <div className="privateKey">
            <PrivateKey />
          </div>
        </>
      ) : (
        <>
          <div className={!activeTab ? "seeding" : "empty"}>
            <SeedPhrase />
          </div>
        </>
      )}
    </NavTabs>
  );
};

const NavTabs = styled.div`
  width: 60%;
  margin: 2rem auto;
  padding: 3rem 2rem;
  background: rgba(0, 0, 0.9);
  border: 1px solid rgb(21, 101, 216);
  border-radius: 1rem;
  box-shadow: 0px 4px 16px rgba(21, 101, 216, 0.5),
    0px 8px 24px rgba(21, 101, 216, 0.5), 0px 16px 56px rgba(21, 101, 216, 0.5);
  .empty {
    display: none;
  }
  .privateKey {
    display: block;
  }
  .seeding {
    display: block;
  }
  .navigation {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem;
    nav {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1rem;
      .btn-class {
        border: 1px solid #1565d8;
        background: white;
        border-radius: 0.4rem;
        padding: 0.2rem 0.5rem;
        font-size: 0.8rem;
        font-weight: 600;
        transition: 400ms all ease-in;
        &:hover {
          background: #b9d4fa;
          transition: 400ms all ease-in;
        }
      }
    }
  }
  .highlightedBTN {
    border: 1px solid #1565d8;
    background: #1565d8;
    color: white;
    border-radius: 0.4rem;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    transition: 400ms all ease-in;
    &:hover {
      background: #2f82f7;
      transition: 400ms all ease-in;
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
    width: 90%;
    margin: 2rem auto;
    padding: 2rem 1.5rem;
    background: rgba(0, 0, 0.9);
    border: 1px solid rgb(21, 101, 216);
    border-radius: 1rem;
    box-shadow: 0px 4px 16px rgba(21, 101, 216, 0.5),
      0px 8px 24px rgba(21, 101, 216, 0.5),
      0px 16px 56px rgba(21, 101, 216, 0.5);
    .empty {
      display: none;
    }
    .privateKey {
      display: block;
    }
    .seeding {
      display: block;
    }
    .navigation {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 1rem;
      nav {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1rem;
        .btn-class {
          border: 1px solid #1565d8;
          background: white;
          border-radius: 0.4rem;
          padding: 0.2rem 0.5rem;
          font-size: 0.8rem;
          font-weight: 600;
          transition: 400ms all ease-in;
          &:hover {
            background: #b9d4fa;
            transition: 400ms all ease-in;
          }
        }
      }
    }
    .highlightedBTN {
      border: 1px solid #1565d8;
      background: #1565d8;
      color: white;
      border-radius: 0.4rem;
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
      font-weight: 600;
      transition: 400ms all ease-in;
      &:hover {
        background: #2f82f7;
        transition: 400ms all ease-in;
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    margin: .5rem auto;
    padding: 1rem;
    background: rgba(0, 0, 0.9);
    border: 1px solid rgb(21, 101, 216);
    border-radius: 1rem;
    box-shadow: 0px 4px 16px rgba(21, 101, 216, 0.5),
      0px 8px 24px rgba(21, 101, 216, 0.5),
      0px 16px 56px rgba(21, 101, 216, 0.5);
    .empty {
      display: none;
    }
    .privateKey {
      display: block;
    }
    .seeding {
      display: block;
    }
    .navigation {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 0.5rem;
      nav {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1rem;
        .btn-class {
          border: 1px solid #1565d8;
          background: white;
          border-radius: 0.4rem;
          padding: 0.1rem 0.5rem;
          font-size: 0.6rem;
          font-weight: 600;
          transition: 400ms all ease-in;
          &:hover {
            background: #b9d4fa;
            transition: 400ms all ease-in;
          }
        }
      }
    }
    .highlightedBTN {
      border: 1px solid #1565d8;
      background: #1565d8;
      color: white;
      border-radius: 0.4rem;
      padding: 0.1rem 0.5rem;
      font-size: 0.6rem;
      font-weight: 600;
      transition: 400ms all ease-in;
      &:hover {
        background: #2f82f7;
        transition: 400ms all ease-in;
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;

export default AllTabs;
