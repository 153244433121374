import styled from "styled-components";

interface addComment {
  connectModal: Boolean;
  text: string;
}

const LoaderComponent = ({ connectModal, text }: addComment) => {
  return (
    <Loader>
      {!connectModal && (
        <>
          <div className="overlay"></div>
          <div className="the-spinner">
            <img
              className="animate-spin"
              src="https://www.svgrepo.com/show/199956/loading-loader.svg"
              alt="Loading icon"
            />
            <p className="text-white text-[1.2rem]">{text} </p>
          </div>
        </>
      )}
    </Loader>
  );
};

const Loader = styled.div`
  width: 100%;
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .the-spinner {
    gap: 1rem;
    width: fit-content;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    img {
      width: 30%;
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: black;
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .the-spinner {
      gap: 1rem;
      width: fit-content;
      position: absolute;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      img {
        width: 10%;
      }
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: black;
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .the-spinner {
      gap: 1rem;
      width: fit-content;
      position: absolute;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      img {
        width: 10%;
      }
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: black;
    }
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .the-spinner {
      gap: 1rem;
      width: fit-content;
      position: absolute;
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      img {
        width: 10%;
      }
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: black;
    }
  }
`;
export default LoaderComponent;
